import React, { useCallback, useState } from "react";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./TermsAndPrivacy.css";

function PrivacyView({ blackStar }) {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  return (
    <header className=" circle-bg valign terms-view">
      <div className="container ">
        <h1 class="wow color-font animated terms-title">Privacy Policy</h1>

        <p>Last updated: May 16, 2024</p>

        <h2>Privacy Policy for 9epoch Platform</h2>

        <h3>Introduction</h3>
        <p>9epoch Pty Ltd ("9epoch", "we", "us", "our", "the Company") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the personal information we collect through the 9epoch platform ("Platform").</p>

        <h3>Collection of Personal Information</h3>
        <p>We may collect personal information from you when you interact with our Platform, including but not limited to:</p>
        <ul>
            <li>Name, email address, and contact information provided when you register for an account;</li>
            <li>Information provided when you upload documents or communicate with us;</li>
            <li>Usage data and analytics related to your interactions with the Platform.</li>
        </ul>
        <p>We may also collect personal information from third parties or publicly available sources where permitted by law.</p>

        <h3>Use of Personal Information</h3>
        <p>We use the personal information we collect for purposes including:</p>
        <ul>
            <li>Providing and improving our services to you;</li>
            <li>Communicating with you, including responding to inquiries and providing customer support;</li>
            <li>Conducting research and analysis to improve our products and services;</li>
            <li>Complying with legal obligations.</li>
        </ul>

        <h3>Disclosure of Personal Information</h3>
        <p>We may disclose your personal information to third parties in the following circumstances:</p>
        <ul>
            <li>To service providers who assist us in operating the Platform and providing our services;</li>
            <li>As required by law, regulatory authorities, or legal processes;</li>
            <li>With your consent or at your direction.</li>
        </ul>

        <h3>Security of Personal Information</h3>
        <p>We implement appropriate technical and organizational measures to protect the personal information we collect and maintain. However, no method of transmission over the Internet or method of electronic storage is completely secure.</p>

        <h3>Access and Control of Your Personal Information</h3>
        <p>You may access, review, update, and correct your personal information held by us by contacting us directly. You may also request that we delete your personal information, subject to legal requirements.</p>

        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on our website or notifying you directly.</p>

        <h3>Contact Us</h3>
        <p>If you have any questions, concerns, or complaints regarding our Privacy Policy or our practices, please contact us at:</p>
        <p>Email: contact@9epoch.com</p>
      </div>

      <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <div className="gradient-circle"></div>
      <div className="gradient-circle two"></div>
    </header>
  );
}

export default PrivacyView;
